import React from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import './reskue_info.scss';

function reskue_info() {
  const { t } = useTranslation('common');

  return (
    <div id="info_section">
      <h3>{t('about.missionTitle')}</h3>
      <p>{t('about.missionDescr')}</p>
      <a
        className="black_link"
        target="blank"
        href="https://whitepaper.reskue.art/"
      >
        {t('about.whitepaper')}
      </a>

      <h3>{t('about.valuesTitle')}</h3>
      <div className="values">
        <p>{t('about.v1')}</p>
        <p>{t('about.v2')}</p>
        <p>{t('about.v3')}</p>
        <p>{t('about.v4')}</p>
      </div>
    </div>
  );
}

export default reskue_info;
