import React, { useEffect } from 'react';
import './about_section.scss';
import ContactSection from './contact_section/contact_section';
import Team from './team/team';
import ReskueInfo from './reskue_info/reskue_info';
import { Helmet } from 'react-helmet-async';

function AboutSection() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Reskue - About us</title>
        <meta
          name="description"
          content="We are a bunch of passionate developers working to reimagine the experience between non-profit organizations and their contributors"
          data-react-helmet="true"
        />
      </Helmet>
      <ReskueInfo />
      <Team />
      <ContactSection />
    </div>
  );
}

export default AboutSection;
