import './team.scss';
import './team_card.scss';
import { ITeamCard } from 'interfaces/team_card.interface';
import eric from 'images/team/ai/eric.jpg';
import tom from 'images/team/ai/tom.jpg';
import React, { useState } from 'react';
import Twitter from 'images/social_media/twitter.png';
import Github from 'images/social_media/github.png';
import Mail from 'images/social_media/mail.png';
import Linkedin from 'images/social_media/linkedin.png';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const team: ITeamCard[] = [
  // {
  //     img_url: nico,
  //     name: "Nico",
  //     job: "Lead Blockchain",
  //     mail: "nicolas@reskue.art",
  //     github: "https://github.com/nboisde",
  //     twitter: "https://twitter.com/0xfancyfox",
  //     linkedin: "https://www.linkedin.com/in/nicolas-boisd%C3%A9-9b05a3137/"
  // },
  {
    img_url: eric,
    name: 'Eric Djavid',
    job: 'CEO',
    mail: 'eric@reskue.art',
    github: 'https://github.com/ericdjavid',
    twitter: 'https://twitter.com/ericdjav',
    linkedin: 'https://www.linkedin.com/in/eric-djavid-2154b991/',
  },
  {
    img_url: tom,
    name: 'Tom Vacherat',
    job: 'CTO',
    mail: 'tom@reskue.art',
    github: 'https://github.com/tvacherat',
    twitter: 'https://twitter.com/Tom_vch',
    linkedin: 'https://www.linkedin.com/in/tomvacherat/',
  },
  // {
  //     img_url: calixte,
  //     name: "Calixte",
  //     job: "Lead Frontend",
  //     mail: "calixte@reskue.art",
  //     github: "https://github.com/Ovoda",
  //     twitter: "https://twitter.com/Ovoda_",
  //     linkedin: "https://www.linkedin.com/in/calixte-de-tourris/"
  // },
];

function Team() {
  const [currentCard, setCurrentCard] = useState({
    img_url: '',
    name: '',
    description: '',
    job: '',
    mail: '',
    github: '',
    twitter: '',
    linkedin: '',
  });
  const [isMemberDescription, setIsMemberDescription] = useState(false);

  function mountCard(e: any, member: any, isCurrentCard: boolean) {
    if (isCurrentCard) {
      e.currentTarget.classList.toggle('opened');
      setIsMemberDescription(false);
      setCurrentCard({
        img_url: '',
        name: '',
        job: '',
        mail: '',
        github: '',
        twitter: '',
        linkedin: '',
        description: '',
      });
    } else {
      setIsMemberDescription(true);
      setCurrentCard(member);
    }
  }

  const { t } = useTranslation('common');

  return (
    <div id="about_us">
      <h3>{t('about.team')}</h3>
      <div id="team_section">
        <div id="team">
          {team.map(member => (
            <div
              key={member.name}
              className={
                'team_card' +
                (currentCard.name === member.name ? ' opened' : '')
              }
              onClick={(e: any) =>
                mountCard(e, member, currentCard.name === member.name)
              }
            >
              <img
                className="profil"
                src={member.img_url}
                alt={member.name + ' ' + member.job}
              />
            </div>
          ))}
        </div>
        {!isMemberDescription && (
          <div className="team_card_info">
            <p className="title">The Team</p>
            <p className="text">{t('about.teamDesc')}</p>
          </div>
        )}

        {isMemberDescription && (
          <div className="team_card_info">
            <p className="title">{currentCard.name}</p>
            <p className="secondary_title">{currentCard.job}</p>
            <p>42, Paris</p>
            <div className="social">
              <a rel="noreferrer" target="_blank" href={currentCard.twitter}>
                <img src={Twitter} />
              </a>
              <a rel="noreferrer" target="_blank" href={currentCard.github}>
                <img src={Github} />
              </a>
              <a href={'mailto:' + currentCard.mail}>
                <img src={Mail} />
              </a>
              <a rel="noreferrer" target="_blank" href={currentCard.linkedin}>
                <img src={Linkedin} />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Team;
